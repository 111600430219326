import { Component, OnDestroy } from '@angular/core';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { Storage } from '@ionic/storage-angular';
import { Subscription } from 'rxjs';
import { TokenStorageService } from './services/token.service';
import { EventBusService } from './shared/event-bus.service';
import { EventData } from './shared/event.class';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnDestroy {

  private roles: string[] = [];
  private isLoggedIn = false;
  public menuItems;
  showAdminBoard = false;
  showModeratorBoard = false;
  username?: string;
  eventBusSub?: Subscription;

  constructor(
    private router: Router,
    private storage: Storage,
    private tokenStorageService: TokenStorageService,
    private eventBusService: EventBusService) {
      this.menuItems = [
        {
          title: 'Strona domowa',
          path: '/home'
        },
        {
          title: 'Auta',
          path: '/cars'
        },
        {
          title: 'Awizacje',
          path: '/permits'
        },
        {
          title: 'Bramy',
          path: '/gates'
        },
        {
          title: 'Kamery',
          path: '/cameras'
        },
        {
          title: 'Ustawienia',
          path: '/settings'
        },
        {
          title: 'Goście',
          path: '/guests'
        },
      ];
  }

  public protectedMenuItems(isAdminRoute: boolean): any[] {
    let items = [];
    if (isAdminRoute) {
      items = [
        {
          title: "Strona domowa",
          path: "/home"
        }
      ];
    } else {
      items = this.menuItems
    }
    if (this.tokenStorageService.getToken()) {
      // elements menu only for admin (without superAdmin)
      if (this.tokenStorageService.isAdmin()) {
            // example
        // items = items.concat([
        //   {
        //     title: 'Tylko admin (bez superAdmina)',
        //     path: '/admin'
        //   }
        // ])
      }
      // elements menu only for superAdmin (without admin)
      if (this.tokenStorageService.isSuperAdmin()) {
            // example
        // items = items.concat([
        //   {
        //     title: 'Tylko superAdmin (bez admina)',
        //     path: '/admin'
        //   }
        // ])
      }
      // elements menu for both roles - admin and superAdmin
      if ((this.tokenStorageService.isAdmin() || this.tokenStorageService.isSuperAdmin())) {
        if (!isAdminRoute) {
          items = items.concat([
            {
              title: 'Panel Administracyjny',
              path: '/admin/users'
            }
          ])
        } else {
          items = items.concat([
            {
              title: 'Użytkownicy',
              path: '/admin/users'
            },
            {
              title: 'Szablony maili',
              path: '/admin/templates'
            }
          ])
        }

      }
      return items
    }
  }

  async ngOnInit() {
    await this.storage.create();
    this.isLoggedIn = !!this.tokenStorageService.getToken();
    this.eventBusSub = this.eventBusService.on('logout', () => {
      this.logout();
      this.router.navigate(["/login"])
    });
    if (this.isLoggedIn) {
      const user = await this.tokenStorageService.getUser();
      this.username = user.username;
    } else {
      this.eventBusService.emit(new EventData('logout', null))
    }
  }

  isLogged() {
    return this.isLoggedIn;
  }

  changeLoggedIn(isLogg: boolean) {
    this.isLoggedIn = isLogg;
  }

  ngOnDestroy(): void {
    if (this.eventBusSub)
      this.eventBusSub.unsubscribe();
  }

  logout(): void {
    this.tokenStorageService.signOut();
    this.isLoggedIn = false;
    this.roles = [];
    this.showAdminBoard = false;
    this.showModeratorBoard = false;
  }
}
