import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
    private host = environment.apiHost;
    private authHost = environment.authHost;

    httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    constructor( private httpClient: HttpClient) { }
    //register

    public resetPassword(formValue:any): Observable<any> {
        if (formValue !== undefined && formValue.username) {
            return this.httpClient.post(
                this.host + "auth/forget-password",
                JSON.stringify(formValue),
                this.httpOptions
            )
        }
    }

    public login(formValue:any): Observable<any> {
        console.log("HOST: ", this.host)
        if (formValue !== undefined && formValue.username && formValue.password) {
            return this.httpClient.post(
                this.authHost + "login",
                JSON.stringify(formValue),
                this.httpOptions
            )
        }
    }

    public signup(formValue:any): Observable<any> {
        if (formValue !== undefined &&
            formValue.userName &&
            formValue.password &&
            formValue.name &&
            formValue.lastName ) {
            return this.httpClient.post(
                this.host + "auth/signup",
                JSON.stringify(formValue),
                this.httpOptions
            )
        }
    }

    public logout(refreshToken: string): Observable<any> {
        return this.httpClient.delete(
            this.host + "login",
            {
                headers: new HttpHeaders({
                    'Authorization': `Bearer ${refreshToken}`,
                    'Content-Type': 'application/json'
                })
            }
        )
    }

    public refreshToken(refreshToken: string): Observable<any> {
        const header = new HttpHeaders({
            'Authorization': `Bearer ${refreshToken}`,
            'Content-Type': 'application/json'
        })
        return this.httpClient.post(
            this.authHost + 'token',
            JSON.stringify({
                token: refreshToken
            }),
            {
                headers: header
            }
        )
    }
}

