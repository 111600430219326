import { HTTP_INTERCEPTORS, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { TokenStorageService } from '../services/token.service';
import { AuthService } from '../services/auth.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { EventBusService } from '../shared/event-bus.service';
import { EventData } from '../shared/event.class';
const TOKEN_HEADER_KEY = 'Authorization';  // for Spring Boot back-end
// const TOKEN_HEADER_KEY = 'x-access-token';    // for Node.js Express back-end
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private tokenService: TokenStorageService,
    private authService: AuthService,
    private eventBusService: EventBusService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {
    let authReq = req;
    //  this.tokenService.getToken().then((token) => {
    const token = this.tokenService.getToken();
      if (token != null) {
        authReq = this.addTokenHeader(req, token);
      }
      return next.handle(authReq).pipe(catchError(error => {
        if (error instanceof HttpErrorResponse && !authReq.url.includes('/login') && !authReq.url.includes('/signup') && error.status === 401) {
          return this.handle401Error(authReq, next);
        }
        return throwError(error);
      }));
      // });
    return throwError("Error: Some problem on intercept");
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
        this.isRefreshing = true;
        this.refreshTokenSubject.next(null);
        const token = this.tokenService.getRefreshToken();
        // this.tokenService.getRefreshToken().then(token => {
        if (token)
          return this.authService.refreshToken(token).pipe(
            switchMap((token: any) => {
              this.isRefreshing = false;
              this.tokenService.saveToken(token.token);
              this.refreshTokenSubject.next(token.token);

              return next.handle(this.addTokenHeader(request, token.token));
            }),
            catchError((err) => {
              this.isRefreshing = false;
              this.eventBusService.emit(new EventData('logout', null));
              this.tokenService.signOut();
              return throwError(err);
          }));
    // })
    }
    return this.refreshTokenSubject.pipe(
        filter(token => token !== null),
        take(1),
        switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
  }
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];
