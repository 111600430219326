import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: '',
    loadChildren: () => import('./pages/menu/menu.module').then( m => m.MenuPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'permits',
    loadChildren: () => import('./pages/permits/permits.module').then( m => m.PermitsPageModule)
  },
  {
    path: 'cars',
    loadChildren: () => import('./pages/cars/cars.module').then( m => m.CarsPageModule)
  },
  {
    path: 'add-car',
    loadChildren: () => import('./pages/cars/add/add-car.module').then( m => m.AddCarPageModule)
  },
  {
    path: 'edit-car/:id',
    loadChildren: () => import('./pages/cars/edit/edit-car.module').then( m => m.EditCarPageModule)
  },
  {
    path: 'guests',
    loadChildren: () => import('./pages/guests/guests.module').then( m => m.GuestsPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'car-info/:id',
    loadChildren: () => import('./pages/cars/info/car-info.module').then( m => m.CarInfoPageModule)
  },
  {
    path: 'gates',
    loadChildren: () => import('./pages/gates/gates.module').then( m => m.GatesPageModule)
  },
  {
    path: 'add-gate',
    loadChildren: () => import('./pages/gates/add/add-gate.module').then( m => m.AddGatePageModule)
  },
  {
    path: 'edit-gate/:id',
    loadChildren: () => import('./pages/gates/edit/edit-gate.module').then( m => m.EditGatePageModule)
  },
  {
    path: 'cameras',
    loadChildren: () => import('./pages/cameras/cameras.module').then( m => m.CamerasPageModule)
  },
  {
    path: 'add-camera',
    loadChildren: () => import('./pages/cameras/add/add-camera.module').then( m => m.AddCameraPageModule)
  },
  {
    path: 'edit-camera/:id',
    loadChildren: () => import('./pages/cameras/edit/edit-camera.module').then( m => m.EditCameraPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
