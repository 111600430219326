// wrazliwe na kolejnosc, nowe role dopisywac na koniec!
export enum ROLES_LIST {
  "User" = 0,
  "Admin" = 1,
  "SuperAdmin" = 2,
  "Manager" = 3,
  "Organizer" = 4,
  "Security" = 5,
}
export interface UserJWT {
  name: string,
  userId: string,
  roles: number[],
  iat?: number,
  exp?: number
}

export const PER_PAGE = 20;
